
body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #fff;
}

.title {
  color: #333;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.app {
  width: 80%;
  max-width: 830px;
  margin: 20px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.clearButton {
  margin-top: 20px;
  padding: 10px;
  background-color: #d6c077;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.clearButton:hover {
  background-color: #93632a;
}


.inputContainer {
  text-align: center;
  margin-bottom: 20px;
}


.title {
  color: #333;
  font-size: 24px;
  margin-bottom: 10px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}

.input {
  padding: 10px;
  margin-bottom: 16px;
  width: 100%;
  box-sizing: border-box;
}

.submitButton {
  padding: 10px;
  background-color: #d6c077;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submitButton:hover {
  background-color: #93632a;
}

.error {
  color: red;
  margin-top: 8px;
  font-size: 14px;
}

/* TableComponent.module.css */

.table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

th {
  background-color: #d6c077;
  color: #fff;
}

.calculation {
  color: #007bff;
}

.sum {
  color: #d6c077;
}
.logo {
  height: 60px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}
